import React from "react";
import Layout from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemWithFinish from "../components/itemWithFinish";
import { graphql } from "gatsby";
const Primer = ({data}) => (
<Layout>
  <Row>
      <Col>
          <h1>Primer Paints</h1>
      </Col>
  </Row>
        {/* PU Primer */}
        <ItemWithFinish
            heading={"PU Primer"}
            data={"2k polyurethane primer grey acts an anti-corrosive paint. It provides superior corrosive resistance & very good binding properties.It is used as primer."}
            package={"20 ltr,4ltr,1ltr"}
            color={"Grey"}
            finish={"Smooth & matt"}
            dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* Epoxy Zinc Rich Primer */}
        <ItemWithFinish
            heading={"Epoxy Zinc Rich Primer"}
            data={"Epoxy zinc rich primer acts an primer coat paint. Provides superior corrosive resistance & very good binding properties. It is used over sand blasting surface."}
            package={"20 ltr,4ltr,1ltr"}
            color={"Grey"}
            finish={"Smooth & matt"}
            dryingTime={"surface dry:10-15 minutes, hard dry:12-16hr"}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* Epoxy Red Oxide Primer */}
        <ItemWithFinish
            heading={"Epoxy Red Oxide Primer"}
            data={"2k Epoxy Redoxide Primer acts an anti corrossive paint. Provides superior corrosive resistance & very good binding properties.It is used as primer."}
            package={"20 ltr,4ltr,1ltr"}
            color={"Red"}
            finish={"Smooth & matt"}
            dryingTime={"surface dry:10-15 minutes, hard dry:12-16hr"}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* Epoxy Zinc Phosphate Primer */}
        <ItemWithFinish
            heading={"Epoxy Zinc Phosphate Primer"}
            data={"2k Epoxy zincchromate( stroncium chromate) yellow primer acts an anti corrossive paint. Provides superior corrosive resistance & very good binding properties.It is used as primer."}
            package={"20 ltr,4ltr,1ltr"}
            color={"Yellow"}
            finish={"Smooth & matt"}
            dryingTime={"surface dry:10-15 minutes, hard dry:12-16hr"}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* Zinc Chromate Yellow Primer (has an error)*/}
        <ItemWithFinish
            heading={"Zinc Chromate Yellow Primer"}
            data={"Wood primer is used for exterior &interior surface, fast drying,& binder nitro cellulose based systems is used as wood coatings & over plywood’s to level the pores to give good finish."}
            package={"20 ltr, 4ltr, 1ltr, 1/2 ltr"}
            color={"Yellow"}
            finish={"Smooth & matt"}
            dryingTime={"surface dry:10-15 minutes, hard dry:12-16hr"}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* Wood Primer White*/}
        <ItemWithFinish
            heading={"Wood Primer White"}
            data={"Wood primer is used for exterior &interior surface, fast drying,& binder nitro cellulose based systems is used as wood coatings & over plywood’s to level the pores to give good finish."}
            package={"20 kg, 10 kg,4kg,1kg,"}
            color={"White"}
            finish={"Smooth & matt"}
            dryingTime={"surface dry time 20 min ."}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* Exterior Wall Primer*/}
        <ItemWithFinish
            heading={"Exterior Wall Primer"}
            data={"Interior wall primer is a water-based wall coating suitable for application interiors as an undercoat to interior emulsions.it gives an excellent opacity and whiteness . Improves appearance of top coat . It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces."}
            package={"20 ltr, 10 ltr, 4ltr, 1ltr"}
            color={"White"}
            finish={"Smooth & matt"}
            dryingTime={"surface dry time 20 min ."}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* Interior Wall Primer*/}
        <ItemWithFinish
            heading={"Interior Wall Primer"}
            data={"Etch primers are for the most part designed to work with either ferrous or non ferrous metals or in some cases both. It is also advisable to use an etch primer on fibre glass and resin moldings & over non ferrous metals to give good adhesion."}
            package={"20 ltr, 4ltr, 1ltr"}
            color={"Yellow"}
            finish={"smooth & semi glossy"}
            dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
        <hr />

        {/* ETCH Primer or Wall Primer*/}
        <ItemWithFinish
            heading={"ETCH Primer or Wall Primer"}
            data={"2k polyurethane primer grey acts an anti-corrosive paint. It provides superior corrosive resistance & very good binding properties.It is used as primer."}
            package={"20 ltr, 4ltr, 1ltr"}
            color={"Grey"}
            finish={"smooth & matt"}
            dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"}
            image={data.primerMain.childImageSharp.fluid}></ItemWithFinish>
 
</Layout>
    
)

export default Primer;
export const query = graphql`
  query {
    primerMain: file(relativePath: { eq: "primerMain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }

`
